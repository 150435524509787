import { useTranslation } from 'next-i18next';
import Link from 'next/link';

interface Error404Props {
  locale: Blog.LocaleType;
}

export default function Error404Component({ locale }: Error404Props) {
  const { t } = useTranslation('common');

  return (
    <>
      <div className="items-center bg-white flex flex-col py-[10rem] px-[2rem]">
        <h1>{t('error404')}</h1>
        <p className="text-[2.6rem] mt-[2rem] opacity-70 text-center">
          <Link href={{ pathname: '/', query: { locale: locale } }} passHref>
            <a className="text-charcoal underline">{t('back')}</a>
          </Link>
        </p>
      </div>
    </>
  );
}
