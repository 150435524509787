import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps, GetStaticPropsContext } from 'next';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';

import Body from 'components/Layout/Body';
import Footer from 'components/Footer/Footer';
import TopNav from 'components/Navigation/TopNav';
import Error404Component from 'components/Utility/Error404';
import { useTrackEventViewedPage } from 'hooks/useTrackEventViewedPage';
import { useTrackEventViewedBlogPage } from 'hooks/useTrackEventViewedBlogPage';

export default function Error404({ locale }) {
  const { t } = useTranslation('common');
  useTrackEventViewedPage({ page_name: '404-not-found' });
  useTrackEventViewedBlogPage();

  return (
    <>
      <Head>
        <meta name="robots" content="noindex, follow" />
        <title>{t('errorTitle')}</title>
      </Head>
      <TopNav translations={[]} locale={locale} />
      <Body>
        <Error404Component locale={locale} />
        <Footer translations={[]} locale={locale} />
      </Body>
    </>
  );
}

export const getStaticProps: GetStaticProps = async (
  context: GetStaticPropsContext
) => {
  const locale =
    (context?.locale as Blog.LocaleType) ?? ('en' as Blog.LocaleType);
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      locale: locale,
    },
  };
};
